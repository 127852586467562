import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import CardMedia from "@material-ui/core/CardMedia";
import img1 from '../../images/toddlers.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  cardmedia: {
    height: 175,
},
}));

export default function DevelopmentToddler() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
          <Card className={classes.paper}>
            <CardMedia
    className={classes.cardmedia}
    image={
    img1
    }
    /></Card>
        </Grid>
      <Grid item xs={12} sm={8}>
          <Card className={classes.paper}>
              <Typography>
              Toddler Day Care is offered to children 18 months- 3 years old.  Two, three, or five day options are available.  We offer enrichment activities such as a weekly Speech and Language Group, a weekly Occupational Therapy Group, and an Amazing Athletes Group twice a month.  The classroom staff consists of an Early Childhood Education Teacher,
               a certified Teacher Assistant, and a Classroom Aide.  The class size is a maximum of 12 students.
              </Typography>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}











